<div class="min-h-full">
  <nav class="border-b border-gray-200 bg-white">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex gap-1">
          @if (utilityService.backButtonAvailable$ | async) {
            <div class="w-9 h-9 self-center mb-2 cursor-pointer">
              <div (click)="utilityService.backButtonClicked$.next(true)">
                <span class="material-symbols-outlined text-4xl">chevron_left</span>
              </div>  
            </div>
          }
  
          <!-- Logo visible always -->
          <div class="flex flex-shrink-0 items-center mb-3" [routerLink]="[ '/home' ]">
            <img class="h-12" src="assets/shared/img/menulogo.svg" alt="chea seed logo">
          </div>

          <!-- Top menu items only visible on large screens -->
          @if (userService.user$ | async; as user) {
            <div class="hidden sm:flex sm:pl-5">
              <ng-container *ngTemplateOutlet="optionsMenuTop; context: { user }"></ng-container>
            </div>
          }
        </div>

        <!-- Profile dropdown only visible on large screens -->
        <div class="hidden sm:ml-6 sm:flex sm:items-center">

          <!-- Profile dropdown -->            
          <div class="relative ml-3">            
            @if (userService.user$ | async; as user) {
              <div>
                @if (user.isAnonymousUser) {
                  @if (shouldShowSignIn$ | async) {
                    <button (click)="this.requestLogin(user)" class="text-chea-purple">
                      Sign In
                    </button>
                  }
                }
                @else {
                  <button [matMenuTriggerFor]="userMenu" 
                    class="max-w-xs items-center rounded-full bg-white"
                    aria-expanded="false" 
                    aria-haspopup="true">
                    <ng-container *ngTemplateOutlet="profileImage; context: { user }"></ng-container>
                  </button>
                  <mat-menu #userMenu="matMenu" xPosition="before" class="w-64">
                    <ng-template matMenuContent>
                      <div class="block px-4 py-2 text-sm text-gray-700 font-bold">
                        <ng-container *ngTemplateOutlet="userInfo; context: { user }"></ng-container>
                      </div>
                      <mat-divider/>
                      <ng-container *ngTemplateOutlet="profileMenu; context: { user }"></ng-container>
                    </ng-template>
                  </mat-menu>
                }
              </div>
            }
          </div>
        </div>

          <!-- Hamburger menu only visible on small screens -->
        <div class="flex items-center self-end sm:hidden">
          @if (userService.user$ | async; as user) {
              @if (user.isAnonymousUser &&  (shouldShowSignIn$ | async)) {
                <button (click)="this.requestLogin(user)" class="text-chea-purple mb-4 mr-4">
                  Sign In
                </button>
              }
          }
          <!-- Mobile menu button -->
          <button 
            [matMenuTriggerFor]="hamburgerMenu"
            class="rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 mb-2" 
            aria-controls="mobile-menu" aria-expanded="false">
            @if (airtableHelper.isLive()) {
              <span class="material-symbols-outlined text-green-300">menu</span>
            }
            @else {
              <span class="material-symbols-outlined text-chea-purple">menu</span>
            }
          </button>
          <mat-menu #hamburgerMenu="matMenu" xPosition="before" class="w-64 font-sans">
            <ng-template matMenuContent>
              <ng-container *ngIf="userService.user$ | async as user">
                <div *ngIf="!user.isAnonymousUser" class="flex items-center px-4 py-2 gap-4 text-base">
                  <ng-container *ngTemplateOutlet="profileImage; context: { user }"></ng-container>
                  <ng-container *ngTemplateOutlet="userInfo; context: { user }"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="optionsMenu; context: { position: 'left', user }"></ng-container>
                <mat-divider/>
                <ng-container *ngTemplateOutlet="profileMenu; context: { user }"></ng-container>
              </ng-container>
            </ng-template>
          </mat-menu>
        </div>
      </div>
    </div>
  </nav>

  <div>
    <main>
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <router-outlet></router-outlet>
      </div>
    </main>
    <!-- <ng-container *ngTemplateOutlet="buildInfo"></ng-container> -->
  </div>
</div>

<ng-template #profileMenu let-user="user">
  <!-- Active: "bg-gray-100", Not Active: "" -->
  @for (item of profileOptions; track item.label) {
    @if (!item.displayIf || item.displayIf(user)) {
      <button mat-menu-item class="font-sans"
        (click)="clickMenuItem(item, user)" 
        [disabled]="item.enableIf && !item.enableIf(user)">
        {{ item.labelfunc ? item.labelfunc() : item.label }}
      </button>
    }
  }
  <ng-container *ngTemplateOutlet="buildInfo"></ng-container>
</ng-template>


<ng-template #userInfo let-user="user">
  <div class="font-sans">
    <div class="text-base font-medium">
      {{ user.isAnonymousUser ? 'Anonymous' : ( user.name || user.docId ) }}
    </div>
    <div class="text-sm font-medium text-gray-500" style="overflow-wrap: anywhere">
      {{ user.name ? user.docId : '' }}
    </div>
  </div>
</ng-template>

<ng-template #optionsMenu let-pos="position" let-user="user">
  @for (item of authorizedOptions$ | async; track item.label) {
    @if (!item.displayIf || item.displayIf(user)) {      
      <button mat-menu-item
        *ngIf="item[pos] === undefined || item[pos]" 
        (click)="clickMenuItem(item, user)"
        class="font-bold">
          {{ item.label }}
      </button>
    }
  }
</ng-template>

<ng-template #optionsMenuTop let-user="user">
  @for (item of authorizedOptions$ | async; track item.label) {
    @if (!item.displayIf || item.displayIf(user)) {      
      <div
        (click)="clickMenuItem(item, user)"
        class="flex justify-center items-center cursor-pointer p-4 px-6 hover:text-chea-purple hover:bg-gray-200">
          <div [style.color]="router.url === item.routerLink ? '#c94b97' : 'black'">
            {{ item.label }}
          </div>
      </div>
    }
  }
</ng-template>

<ng-template #buildInfo>
  <ng-container *ngIf="appInfo$ | async as info">
    <mat-divider/>
    <div class="w-full text-xs text-gray-400 p-4">
      <div>Release: {{ info.environment?.releaseTag }}</div>
      <div *ngIf="info.environment?.patchLevel > 0">PatchLevel: {{ info.environment?.patchLevel }}</div>
      <div>Build: {{ info.environment?.buildTag }}</div>
      <div>Content: {{ info.contentTimestamp }}</div>
      <!-- <div><b>{{ info.user.docId }}</b></div> -->
    </div>
  </ng-container> 
</ng-template>

<ng-template #profileImage let-user="user">
  @if (user.isAnonymousUser) {
    <div class="text-chea-purple">Sign In</div>
  }
  @else if (!user.photoURL) {
    <div class="material-symbols-outlined">person</div>
  }
  @else {
    <img class="h-8 w-8 rounded-full" [src]="user.photoURL" alt="">
  }
  <!-- <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""> -->
</ng-template>

<!-- Best practice:  Name the modal and use an ngIf to determine if it should open -->

<ion-modal #authModal
  *ngIf="userService.requestLogin$ | async as enabled"
  [isOpen]="enabled" 
  [canDismiss]="(userService.requireLogin$ | async) === false"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="dismissAuth()"
  style="--height: 310px; --width: 85%; --min-width: 300px; --max-width: 400px">
  <ng-template>
    <ion-content class="w-full h-80">
      <div class="w-full h-full flex items-center justify-center">
        <lib-login></lib-login>
      </div>  
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #purchaseModal
  *ngIf="conversationService.waitingForPurchase$ | async as enabled"
  [isOpen]="enabled" 
  [canDismiss]="(userService.requestPayment$ | async) === true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="dismissPurchase(true)"
  style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 80vh">
      <cheaseed-purchase></cheaseed-purchase>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #showHelp
  *ngIf="showHelp$ | async as enabled"
  [isOpen]="enabled" 
  [canDismiss]="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="this.showHelp$.next(false);"
  style="--height: 25vh; --width: 45%; --min-width: 300px; --max-width: 500px">
  <ng-template>
    <ion-content style="height: 25vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="showHelp.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-12 w-full h-full flex items-center justify-center">
        <cheaseed-global key="portal.help.message" [markdown]="true" default="Help is on the way!"></cheaseed-global>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #syncProgressModal
  *ngIf="syncProgress() as progress"
  [isOpen]="!!progress" 
  [canDismiss]="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="this.syncProgress.set('')"
  style="--height: 25vh; --width: 45%; --min-width: 300px; --max-width: 500px">
  <ng-template>
    <ion-content style="height: 25vh">
      <div class="h-40 flex flex-col items-center justify-center text-lg">
        <mat-spinner class="chea-mat-spinner"></mat-spinner>
        {{ progress }}
      </div>
    </ion-content>
  </ng-template>
</ion-modal>